<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Available vehicle
                  <span v-if="route">for {{ route.title }}</span>
                  routes
                </h4>
              </div>
              <!-- <div class="breadcrumb-right">
                  <v-btn @click="manageVehicle()" x-large text>
                    Manage vehicles
                  </v-btn>
                  <v-btn v-if="checkIsAccessible('vehicle', 'create')" @click="__add()" class="mt-1 btn btn-primary" dark>
                    <i class="fa fa-plus"></i>
                    Add vehicle to routes
                  </v-btn>
                </div> -->
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column">Route</th>
                    <th class="wrap-column">Bus</th>
                    <th class="wrap-column">Action</th>
                  </tr>
                </thead>
                <draggable
                  v-show="routesVehicles.length > 0"
                  @change="sort"
                  v-model="routesVehicles"
                  class="text-left"
                  tag="tbody"
                >
                  <tr
                    v-for="(routesVehicles, index) of routesVehicles"
                    :key="routesVehicles.title"
                  >
                    <td scope="row" class="wrap-column">
                      {{ routesVehicles.routes.title }}
                      <br />
                      <span
                        v-if="
                          routesVehicles &&
                          routesVehicles.routes &&
                          routesVehicles.routes.start_point
                        "
                      >
                        {{ routesVehicles.routes.start_point }} -
                        {{ routesVehicles.routes.end_point }}</span
                      >
                    </td>
                    <td scope="row" class="wrap-column">
                      {{ routesVehicles.vehicles.title }}
                      <br />
                      {{ routesVehicles.vehicles.reg_no }}
                    </td>

                    <td>
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('vehicle', 'delete')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="__delete(routesVehicles.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="routesVehicles.length == 0">
                  <td colspan="3" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="routesVehicles.length > 0"
                class="pull-right mt-7"
                @input="__get"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
  <script>
import draggable from "vuedraggable";

import VehiclesService from "@/core/services/Transport/Vehicles/VehiclesService";
const vehiclesService = new VehiclesService();
import RoutesService from "@/core/services/Transport/Routes/RoutesService";
const routesService = new RoutesService();
import RoutesVehicleService from "@/core/services/Transport/RoutesVehicle/RoutesVehicleService";
const routesVehicleService = new RoutesVehicleService();

export default {
  name: "routes-vehicles",
  display: "Table",
  order: 8,
  components: {
    draggable,
  },
  computed: {
    routeId() {
      return this.$route.params.routeId;
    },
  },
  data() {
    return {
      routesVehicles: [],
      vehicles: [],
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      route: null,
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
      },
      videoModel: false,
      video: {},
      dragging: false,
      loading: false,
    };
  },
  mounted() {
    if (this.routeId) {
      this.search.route = this.routeId;
      this.getRoutes();
    }
    this.__get();
  },
  methods: {
    __get() {
      this.loading = true;
      routesVehicleService
        .paginate(this.search)
        .then((response) => {
          this.routesVehicles = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    getRoutes() {
      routesService.show(this.routeId).then((response) => {
        this.route = response.data.route;
      });
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            routesVehicleService
              .delete(id)
              .then((response) => {
                this.__get();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    manageVehicle() {
      this.$router.push({
        name: "vehicle",
      });
    },
    sort() {
      routesVehicleService
        .sort(this.routesVehicles)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.__get();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
  },
};
</script>
  <style scoped>
.buttons {
  margin-top: 35px;
}
</style>
  