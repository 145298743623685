<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Route students</h4>
  
                </div>
                <!-- <div class="breadcrumb-right" v-if="selected.length > 0 && route_summary.exam_status=='completed'">
                  <div class="card-toolbar">
                    <v-btn @click="notifyStudents()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Notify result
                    </v-btn>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="card-body">
              <div class="">
                <table class="table">
                  <thead>
                  <tr class="px-3">
                 
                    <th class="" style="max-width: 200px !important; white-space: pre-wrap;">Student</th>
                    <th class="">Stop</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-show="route_summary.length > 0" v-for="(student, index) of route_summary" :key="index">
                
                    <td style="max-width: 200px !important; white-space: pre-wrap;">
                      <a 
                         class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ student.student_name ? student.student_name : '-' }}
                      </a><br/>
  
                        <b>Symbol No.:</b> {{ student.symbol_no }}<br>
                        <b v-if="student.student_id">ID:</b> {{ student.student_id }} <br v-if="student.student_id">
                        <!-- <b>Exam symbol No.:</b> {{ student.exam_symbol_no }} <br> -->
  
  
                    </td>
                   <td class=" text-left" style="max-width: 100px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">
                       {{ student.stop_name ? student.stop_name : '-' }}
                      </span>
                   </td>
                  
               
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
             
                        </b-dropdown>
                      </template>
                    </td>
  
                  </tr>
                  <tr v-if="route_summary.length == 0">
                    <td colspan="11" class="text-center">
                      No students available
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
  
           
  
            </div>
          </div>
        </div>
      </div>
  
    </v-app>
  </template>
  
  <script>
  import ExamService from "@/core/services/exam/ExamService";
  import StudentResultService from "@/core/services/user/student/result/StudentResultService";
  import {API_URL, APP_URL} from "@/core/config";
  import StudentSettingService from "@/core/services/user/student/StudentSettingService";

  const resultService = new StudentResultService();
  const examService = new ExamService();
  const transportAllocation = new StudentSettingService();

  export default {
    name: "Students",
    props: ['exam'],
    data() {
      return {
        status: {
          result_status: null,
          exam_status: null,
          result: null
        },
        route_summary: [],
        selected: [],
        newColorarray: [],
        headers: [
          {text: 'CRN No.', value: 'crn_no'},
          {text: 'Name', value: 'name'},
        ],
      }
    },
    mounted() {
      // this.getAssignedStudents();
      this.routeId = this.$route.params.routeId;
      this.getAllTransportRoute();

    },
    methods: {
      studentSummary(item){
        this.$router.push(
            {
              name: 'students-summary',
              params: {id: item.student_id}
            }
        )
      },
      getAllTransportRoute() {
      this.loading = true;
      transportAllocation
        .getRouteId(this.routeId, this.search, this.page)
        .then((response) => {
          this.route_summary = response.data.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  