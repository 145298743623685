import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class RoutesVehicleService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/transport/vehicles-routes`;

    }

    paginate(data = null, index = null) {
        let url = `${this.#api}`
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    sort(data) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }


    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    parentCategories() {
        let url = `${this.#api}/parent/all`;
        return apiService.get(url);
    }

    activeAll() {
        let url = `${this.#api}/active/all`;
        return apiService.get(url);
    }
}
