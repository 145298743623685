<template>
  <div>
    <div class="row pl-8 pr-8 pt-5 pb-5">
      <div class="col-md-12">
        <h4>Route information</h4>
        <hr />
      </div>
      <div class="col-md-6">
        <b> Route title <title></title>: </b>
        <span>{{ routes.title }}</span>
        <hr />
        <b> Route category <title></title>: </b>

        <span>{{ routes.route_category.title }}</span>
      </div>
      <div class="col-md-6">
        <b> Route code: </b>
        <span>{{ routes.route_code }}</span>
      </div>
      <div class="col-md-6">
        <b> Fee: </b>
        <span>{{ routes.fee }}</span>
      </div>
      <div class="col-md-6">
        <b> Fee frequency: </b>
        <span>{{ routes.fee_frequency }}</span>
      </div>
      <!-- <div class="col-md-6">
        <b> Bus registration no.: </b>
        <span>{{ routes.bus.reg_no }}</span>
      </div> -->
      <div class="col-md-6">
        <b> Start ponit: </b>
        <span>{{ routes.start_point }}</span>
      </div>
      <div class="col-md-6">
        <b> End point: </b>
        <span>{{ routes.end_point }}</span>
      </div>
    </div>
  </div>
</template>
  
<script>
import RoutesService from "@/core/services/Transport/Routes/RoutesService";

const routesService = new RoutesService();
export default {
  name: "dashboard",
  props: ["routes"],
  components: {},
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      },

      route_summary: [],
      attrs: false,

      routes: {},
      programs: [],
      exams: [],
      // routes: null,
      model: null,
      isLoading: false,
      dialog: false,
      menu2: false,
      loading: false,
      menu: false,
      page: null,
      total: null,
      isBusy: false,
      load_notify: false,
      attendance_dialog: false,
      perPage: null,
      attendance_file: null,
    };
  },
  mounted() {
    this.routeId = this.$route.params.routeId;
    this.__get();
  },

  methods: {
    __get() {
      this.loading = true;
      routesService
        .show(this.routeId)
        .then((response) => {
          this.routes = response.data.route;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
  },
};
</script>