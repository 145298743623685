<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Available bus stops</h4>
              </div>
              <!-- <div class="breadcrumb-right">
                  <v-btn v-if="checkIsAccessible('stops', 'create')" @click="addRouteStop()"
                    class="mt-4 btn btn-primary  mr-1" dark>
                    <i class="fa fa-plus"></i>
                    Add stop
                  </v-btn>
  
                </div> -->
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    outlined
                    v-on:keyup.enter="__get()"
                    v-model="search.title"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="__get()"
                    v-model="search.active"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click="__get()"
                    class="btn btn-primary btn-search w-35"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Routes</th>

                    <th class="px-3 wrap-column">Is start ?</th>
                    <th class="px-3 wrap-column">Is end ?</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <draggable
                  v-show="stops.length > 0"
                  @change="sort"
                  v-model="stops"
                  class="text-left"
                  tag="tbody"
                >
                  <tr v-for="(stop, index) of stops" :key="stop.title">
                    <td class="px-2 wrap-column">
                      <a
                        href="text-primary font-weight-bolder text-hover-primary mb-1"
                        @click="__edit(stop.id)"
                        class="mr-2"
                      >
                        <i class="fa fa-sort"></i> &nbsp;{{ stop.title }}</a
                      >&nbsp;&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="stop.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        {{ stop.routes.title }}
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span
                        class="badge text-lg`"
                        v-bind:class="{
                          'badge-primary': stop.is_start,
                          'badge-danger': !stop.is_start,
                        }"
                        >{{ stop.is_start ? "Yes" : "No" }}</span
                      >
                    </td>
                    <td class="px-3 wrap-column">
                      <span
                        class="badge text-lg mt-2"
                        v-bind:class="{
                          'badge-primary': stop.is_end,
                          'badge-danger': !stop.is_end,
                        }"
                        >{{ stop.is_end ? "Yes" : "No" }}</span
                      >
                    </td>
                    <td class="wrap-column">
                      <span
                        class="badge text-lg mt-2"
                        v-bind:class="{
                          'badge-success': stop.is_active,
                          'badge-danger': !stop.is_active,
                        }"
                        >{{ stop.is_active ? "Active" : "Inactive" }}</span
                      >
                    </td>
                    <td class="pr-0 text-center">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <!--                                                                              <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                                                <a href="#" class="navi-link" @click="detail(faq.id)">-->
                          <!--                                                                                        <span class="navi-icon">-->
                          <!--                                                                                          <i class="fas fa-eye"></i>-->
                          <!--                                                                                        </span>-->
                          <!--                                                                                  <span class="navi-text"> View </span>-->
                          <!--                                                                                </a>-->
                          <!--                                                                              </b-dropdown-text>-->
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('stops', 'edit')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="__edit(stop.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('stops', 'delete')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteStop(stop.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="stops.length == 0">
                  <td colspan="6" class="text-center">
                    <strong>
                      <strong>No data available to display.</strong>
                    </strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="stops.length > 0"
                class="pull-right mt-7"
                @input="__get"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
  <script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import draggable from "vuedraggable";
import StopsService from "@/core/services/Transport/StopsService";
import RoutesService from "@/core/services/Transport/Routes/RoutesService";

const stopsService = new StopsService();
const routesService = new RoutesService();
export default {
  name: "stops",
  display: "Table",
  order: 8,
  components: {
    draggable,
  },
  computed: {
    routeId() {
      return this.$route.params.routeId;
    },
  },
  data() {
    return {
      stops: [],
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      page: null,
      route: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
        route_id: null,
      },
      videoModel: false,
      video: {},
      dragging: false,
      loading: false,
    };
  },
  mounted() {
    if (this.routeId) {
      this.search.route = this.routeId;
      this.getRoutes();
    }
    this.__get();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Album", route: "wizard-1" },
      { title: "Album" },
    ]);
  },
  methods: {
    fetch() {
      if (this.routeId) {
        this.search.route = this.routeId;
        this.getRoutes();
      }
      this.__get();
    },
    __get() {
      this.loading = true;
      stopsService
        .paginate(this.search)
        .then((response) => {
          this.stops = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    getRoutes() {
      routesService.show(this.routeId).then((response) => {
        this.route = response.data.route;
      });
    },
    getByRoute() {
      stopsService.getByRoute(this.routeId).then((response) => {
        this.stops = response.data.stops;
        // console.log(response)
      });
    },
    addRouteStop() {
      this.$refs["stop"].showModal();
    },
    __edit(id) {
      this.$refs["stop"].showModal(id);
    },

    deleteStop(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            stopsService
              .delete(id)
              .then((response) => {
                this.fetch();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    sort() {
      if (this.search.active == "active") {
        stopsService
          .sort(this.stops)
          .then((res) => {
            this.$snotify.success("Sorted");
            this.fetch();
          })
          .catch((err) => {
            this.$snotify.success("Sorted Error");
          });
      }
    },
  },
};
</script>
  <style scoped>
.buttons {
  margin-top: 35px;
}
</style>
  