<template>
  <v-app>
    <v-row class="m-1" v-if="isBusy">
      <v-col cols="2">
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item, list-item-two-line, list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item, list-item-two-line, list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="10">
        <v-skeleton-loader
          v-bind="attrs"
          type="article, article"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="article, article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!isBusy">
      <v-col cols="3" md="3" lg="3" xl="2">
        <v-card flat v-if="routes">
          <v-card-title class="justify-center"> Route summary </v-card-title>
          <v-card-text>
            <div class="text-center" v-if="routes">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
              <span class="font-weight-bold mr-2">Route name.</span>

                <strong class="heading">{{ routes.title }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2"
                   v-if="routes && routes.route_code">
                <span class="font-weight-bold mr-2">Route code.</span>
                <strong class="heading">{{ routes.route_code }}</strong>
              </div>
              <!-- <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="routes && routes.route_bus"
              >
                <span class="font-weight-bold mr-2">Bus.</span>
                <strong class="heading">{{ routes.bus }}</strong>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Driver</span>
                <strong class="heading">{{ routes.driver }}</strong>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Start point</span>
                <strong class="heading">{{ routes.start_point }}</strong>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="routes && routes.end_point"
              >
                <span class="font-weight-bold mr-2">End point</span>
                <strong class="heading">{{ routes.end_point }}</strong>
              </div> -->
              <!-- <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Type</span>
                <strong class="heading">{{ route_summary.type }}</strong>
              </div>

              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Start date</span>
                <strong class="heading">{{ route_summary.formatted_start_date }}</strong>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">End date</span>
                <strong class="heading">{{ route_summary.formatted_end_date }}</strong>
              </div> -->
              <!-- <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2"
                  >Total assigned students on this route</span
                >
                <strong class="heading">{{ route_summary.total_student || 0 }}</strong>
              </div> -->
              <!-- <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Total attendance days</span>
                <strong class="heading">{{
                  route_summary.total_attendance_days || 0
                }}</strong>
              </div> -->
            </div>
            <v-tabs class="menu-parent" vertical v-model="model">
              <v-tab>
                <v-icon left>
                  fas fa-bar-chart
                </v-icon>
                Summary
              </v-tab>
          
              <v-tab>
                <v-icon left>
                  fas fa-user-graduate
                </v-icon>
                Students
              </v-tab>

              <v-tab>
                <v-icon left>
                  fa fa-bus
                </v-icon>
                Bus
              </v-tab>

              <v-tab>
                <v-icon left>
                  fas fa-stop
                </v-icon>
                Stops
              </v-tab>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9" md="9" lg="9" xl="10">
        <v-tabs-items v-model="model">
          <v-tab-item>
            <dashboard :routes="routes" v-if="routes"></dashboard>
          </v-tab-item>

    
          <v-tab-item>
            <student :routes="routes"></student>
          </v-tab-item>

          <v-tab-item>
            <vehicles></vehicles>
          </v-tab-item>
          <v-tab-item>
            <stops></stops>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
import RoutesService from "@/core/services/Transport/Routes/RoutesService";
import Dashboard from "./Dashboard";
import Student from "./Student";
import Stops from "./Stops";
import Vehicles from "./Vehicles";

const routesService = new RoutesService();

// const examService = new ExamService();
const transportAllocation = new StudentSettingService();

export default {
  components: {
    Dashboard,
    Student,
    Stops,
    Vehicles
  },
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      },
  
      types: [
        {
          text: "Terminal",
          value: "terminal",
        },
        {
          text: "Class Test",
          value: "class_test",
        },
        {
          text: "Board",
          value: "board",
        },
      ],
      route_summary: [],
      attrs: false,
  
      routes: {},
      programs: [],
      exams: [],
      exam: null,
      model: null,
      isLoading: false,
      dialog: false,
      menu2: false,
      loading: false,
      menu: false,
      page: null,
      total: null,
      isBusy: false,
      load_notify: false,
      attendance_dialog: false,
      perPage: null,
      attendance_file: null,
    };
  },
  mounted() {
    this.routeId = this.$route.params.routeId;
    this.getAllTransportRoute();
    this.__get();
  },

  methods: {
    __get() {
      this.loading = true;
      routesService
        .show(this.routeId)
        .then(response => {
          this.routes = response.data.route;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          // console.log(error);
        });
    },
    updateExam() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            examService.update(this.exam.id, this.exam).then((response) => {
              this.$snotify.success("Information updated");
              this.getExam();
            });
          } else {
            this.getExam();
          }
        },
      });
    },
    deleteExam(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            examService.delete(id).then((response) => {
              this.$snotify.success("Information deleted");
              this.$tabs.close().then((response) => {
                this.$router.push({ name: "exams" });
              });
            });
          }
        },
      });
    },

    getAllTransportRoute() {
      this.loading = true;
      transportAllocation
        .getRouteId(this.routeId, this.search, this.page)
        .then((response) => {
          this.route_summary = response.data.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.menu-parent .v-tab {
  justify-content: flex-start !important;
}
</style>
