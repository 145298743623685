import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StudentSettingService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    paginate(userId) {
        let url = `${this.#api}/${userId}/student-setting`;
        return apiService.get(url);
    }

    update(userId,studentSettingId, data) {
        let url = `${this.#api}/${userId}/student-setting/${studentSettingId}`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/student-setting`
        return apiService.post(url, data);

    }

    show(userId, studentSettingId) {
        let url = `${this.#api}/${userId}/student-setting/${studentSettingId}`
        return apiService.get(url);
    }

    delete(userId, studentSettingId) {
        let url = `${this.#api}/${userId}/student-setting/${studentSettingId}`
        return apiService.delete(url);
    }

    getGrades(userId) {
        let url = `${this.#api}/student/${userId}/grades`;
        return apiService.get(url);
    }
    // student/{studentId}/grades/gradeId
    updateGrade(studentId,gradeId, data) {
        let url = `${this.#api}/student/${studentId}/grades/${gradeId}/update`;
        return apiService.post(url, data);

    }

    saveOptionalCourses(data) {
        let url = `${this.#api}/student/save/optional-courses`;
        return apiService.post(url,data);
    }

    updateDiscount(studentId, id, data) {
        let url = `${this.#api}/student/${studentId}/student-setting/${id}/discount`
        return apiService.post(url,data);
    }
    getRouteId(routeId){
        let url = `${this.#api}/student/${routeId}/get-student-route`;
        return apiService.get(url);
    }

    getStatementStudent(studentId, data = {}){
        let url = `${this.#api}/student/${studentId}/statement`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}
